<template>
    <div>
        <div class="table-container">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Produto</th>
                        <th>Percentual Desconto</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!itemsSelected.length">
                        <td colspan="4" style="text-align: center;">Adicionar Produtos</td>
                    </tr>
                    <tr v-for="(i, index) in itemsSelected">
                        <td>{{ i.idExibicao || i.id }}</td>
                        <td>{{ i.descComp || i.desc }}</td>
                        <td>
                            <input-percent v-model="i.percDesc" style="width: 150px; text-align: right;"></input-percent>
                        </td>
                        <td>
                            <button class="button button-error"
                                    title="Remover"
                                    v-on:click="()=>deselectItem(i, index)"
                            >
                                <strong>&times;</strong>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <label>
            <strong>Selecione os produtos na lista abaixo:</strong>
        </label>
        <div class="search-select">
            <input class="form-control" placeholder="Pesquisar por ID, descrição ou referência" type="text" v-model="searchQuery"/>
            <ul class="list">
                <li class="list-item" v-for="item in filteredItems" v-on:click="() => selectItem(item)">
                    <div style="display: flex; align-items: center; justify-content: space-between; margin-right: 50px;">
                        <span>
                            {{ item.idExibicao || item.id }} - {{ item.descComp | truncate(80) }}
                        </span>
                    </div>
                    <button class="button button-primary add-item" title="Adicionar">
                        &plus;
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>
.table-container {
  max-height: 230px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.table tbody > tr:nth-child(even) td {
  background-color: #fff;
}

.table tbody > tr:nth-child(odd) td {
  background-color: #f1f1f1;
}

.table tbody > tr > td {
  padding: 6px;
}

.list {
  padding: 10px 0;
  height: 200px;
  overflow-y: auto;
}

.list-item {
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  position: relative;
}

.list-item:hover {
  background-color: #ddd !important;
}

.list-item:nth-child(odd) {
  background-color: #f1f1f1;
}

.list-item:nth-child(even) {
  font-size: 14px;
}

.add-item {
  padding: 1px 5px;
}

.input-desconto {
  width: 100px;
  border: 1px solid #999999;
  border-radius: 4px;
  text-align: right;
}
</style>

<script>
import _filter from 'lodash/filter';
import _some from 'lodash/some';
import _map from 'lodash/map';
import { normalizeString, isNumeric } from '@/helpers/utils';

export default {
  name: 'multiselectable',
  props: [
    'campanha',
    'items',
    'itemsSelected',
    'selectCallback',
    'deselectCallback',
    'itemKey',
  ],
  data() {
    return {
      searchQuery: '',
      selectedItems: [],
    };
  },
  computed: {
    filteredItems() {
      if (this.searchQuery === '') {
        return this.diffItems(this.items);
      }
      const searchItems = _filter(this.items, (item) => {
        return (
          this.filterFields(
            this.searchQuery,
            [
              item.id,
              item.codBar,
              item.descComp,
              item.idExibicao
            ]
          )
        );
      });
      return this.diffItems(searchItems);
    },
  },
  methods: {
    selectItem(item) {
      const newItem = {
        id: item.id,
        idExibicao: item.idExibicao,
        desc: item.descComp,
        qtde: item.qtde || 1,
        percDesc: item.percDesc || this.campanha.percDesc,
      };

      this.selectedItems.push(item);
      this.selectCallback(newItem);
    },
    filterFields(searchQuery, fieldList) {
      return _some(fieldList, (field) => {
          if (!isNumeric(searchQuery)) {
            return normalizeString(String(field))
              .toLowerCase()
              .indexOf(normalizeString(searchQuery).toLowerCase()) >= 0
          }
          return +searchQuery === +field;
        }
      );
    },
    deselectItem(item, index) {
      this.selectedItems.splice(index, 1);
      this.deselectCallback(item[this.itemKey]);
    },
    diffItems(items) {
      return _filter(items, (item) => {
        return !_some(
          this.selectedItems,
          (selected) => selected.id === item.id
        );
      }).slice(0, 15);
    },
    reloadItems() {
      this.selectedItems = _filter(this.itemsSelected, (item) => {
        return _some(this.itemsSelected, (selected) => selected.id === item.id);
      }).slice(0, 15);
    },
  },
  filters: {
    truncate: (text, stop, clamp) => {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
    },
  },
  created() {
    this.reloadItems();
  },
};
</script>
