<template>
    <div class="xrow" v-else>
        <h2>
            Brindes Selecionados:
            <!--            <small class="validation-error">{{ validation.firstError("item.mix") }}</small>-->
        </h2>
        <ListaBrindes
                itemKey="id"
                :items="produtos"
                :itemsSelected="item.brindes"
                :select-callback="addBrinde"
                :deselect-callback="removeBrinde"
        />
    </div>
</template>

<script>
import ListaBrindes from './ListaBrindes';
import _remove from 'lodash/remove';

export default {
  name: 'Brinde',
  props: ['item', 'produtos'],
  components: {
    ListaBrindes,
  },
  methods: {
    addBrinde(item) {
      this.item.brindes.push(item);
    },
    removeBrinde(id) {
      const brindes = [...this.item.brindes];
      _remove(brindes, (n) => +n.id === +id);
      this.item.brindes = brindes;
    },
  },
};
</script>

<style scoped>
</style>
