<template>
    <modal name="campanhaCreate" height="auto" width="900" :scrollable="true" :clickToClose="false"
           @before-close="reset">
        <CloseModal :close="close"/>
        <div class="modalbox">
            <div class="modalbox-content">
                <CTabs variant="tabs" class="nav-tabs-boxed">
                    <CTab :title="`Criar ${descSingular}`">
                        <div class="xrow">
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label for="">Nome:</label>
                                    <input class="form-control" maxlength="250" v-model="item.nome"/>
                                    <div class="message">{{ validation.firstError('item.nome') }}</div>
                                </div>
                            </div>
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label for="">Data de Início:</label>
                                    <datetime
                                            input-class="form-control"
                                            type="datetime"
                                            v-model="item.dataInicio"
                                            :max-datetime="item.dataFim"
                                            :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                                            value-zone="local"
                                    />
                                    <div class="message">{{ validation.firstError('item.dataInicio') }}</div>
                                </div>
                            </div>
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label for="">Data de Término:</label>
                                    <datetime :disabled="item.dataInicio === null"
                                              input-class="form-control"
                                              type="datetime"
                                              :min-datetime="item.dataInicio"
                                              v-model="item.dataFim"
                                              :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                                              value-zone="local"
                                    />
                                    <div class="message">{{ validation.firstError('item.dataFim') }}</div>
                                </div>
                            </div>
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label for="">Tipo de Apuração:</label>
                                    <select class="form-control" v-model="item.tipoApuracao">
                                        <option value="P">Pedido</option>
                                        <option value="N">Nota Fiscal</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label for="">Tipo:</label>
                                    <select class="form-control" v-model="item.tipoCampanha"
                                            :disabled="(item.tipoCampanha === 'D' && item.dimensoes.length > 0) || (item.tipoCampanha === 'M' && item.mix.length > 0)">
                                        <option value="D">Dimensão</option>
                                        <option value="M">Mix de Produtos</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="xrow" v-if="item.tipoCampanha === 'D'">
                            <h2>DIMENSÕES SELECIONADAS:
                                <small class="validation-error">{{ validation.firstError('item.dimensoes') }}</small>
                            </h2>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Descrição</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-if="!item.dimensoes.length">
                                        <td colspan="3" style="text-align: center;">Adicionar Dimensao</td>
                                    </tr>
                                    <tr v-for="(dim, index) in item.dimensoes">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ getDescricaoDimensao(dim) }}</td>
                                        <td>
                                            <button class="button button-error" title="Remover"
                                                    v-on:click="()=>removeDimensao(index)">
                                                <strong>&times;</strong>
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <h2>ADICIONAR DIMENSÃO:</h2>
                            <div class="col-1-4">
                                <div class="form-group">
                                    <label>Quando:</label>
                                    <select class="form-control" v-model="dimensaoForm.indice"
                                            v-on:change="changeDimensao">
                                        <option value="-1" v-if="!dimensao.dimensao">Selecione</option>
                                        <option v-for="(dim, index) in dimensoes" :value="index">{{ dim.label }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-1-3">
                                <div class="form-group">
                                    <label>For:</label>
                                    <div class="input-group">
                                        <select class="form-control" v-model="dimensao.dimensao">
                                            <option v-for="(item, index) in getDimensoesItens" :key="index"
                                                    :value="item.id" :selected="index === 0">{{ item.desc }}
                                            </option>
                                        </select>
                                        <button class="button button-primary input-group-addon-right"
                                                :disabled="dimensaoForm.indice < 0" title="Adicionar"
                                                v-on:click="addDimensao">+
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="xrow" v-else>
                            <h2>PRODUTOS SELECIONADOS:
                                <small class="validation-error">{{ validation.firstError('item.mix') }}</small>
                            </h2>
                            <Multiselectable :items="produtos" :itemsSelected="item.mix" itemKey="id"
                                             :select-callback="addProdutoMix" :deselect-callback="removeProdutoMix"/>
                        </div>
                        <hr>
                        <div class="xrow">
                            <h2>OPERADORES x METAS:
                                <small class="validation-error">{{ validation.firstError('item.metasOperadores') }}
                                </small>
                            </h2>
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Nome</th>
                                        <th>Positivação</th>
                                        <th>Percentual de Mix</th>
                                        <th>Valor Meta</th>
                                        <th></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-if="!item.metasOperadores.length">
                                        <td colspan="6" style="text-align: center;">Adicionar Operadores</td>
                                    </tr>
                                    <tr v-for="(operador, index) in item.metasOperadores">
                                        <td>{{ operador.codOp }}</td>
                                        <td>{{ getNomeOperador(operador.codOp) }}</td>
                                        <td>{{ toPercent(operador.positivacao) }}</td>
                                        <td>{{ toPercent(operador.percMix) }}</td>
                                        <td>{{ toMonetary(operador.vlMeta) }}</td>
                                        <td>
                                            <button class="button button-error" title="Remover"
                                                    v-on:click="()=>removeOperador(index)">
                                                <strong>&times;</strong>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" style="text-align: right;">TOTAL: {{ getMetaTotal }}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="xrow">
                            <h2>ADICIONAR OPERADOR:</h2>
                            <div class="col-1-4">
                                <div class="form-group">
                                    <label for="">Operador</label>
                                    <select class="form-control" v-model="metaOperador.codOp">
                                        <option value="">Selecione</option>
                                        <option v-for="(op) in operadoresFiltrados" :value="op.codOp">{{op.nome}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-1-4">
                                <div class="form-group">
                                    <label for="">Positivação</label>
                                    <the-mask :mask="['#%','##%']" class="form-control" type="text"
                                              v-model="metaOperador.positivacao"/>
                                </div>
                            </div>
                            <div class="col-1-4">
                                <div class="form-group">
                                    <label for="">Percentual de Mix</label>
                                    <the-mask :mask="['#%','##%']" class="form-control" type="text"
                                              v-model="metaOperador.percMix"/>
                                </div>
                            </div>
                            <div class="col-1-4">
                                <div class="form-group">
                                    <label for="">Valor Meta:</label>
                                    <div class="input-group">
                                        <money class="form-control" v-model="metaOperador.vlMeta" v-bind="monetary"/>
                                        <button class="button button-primary input-group-addon-right"
                                                :disabled="!metaOperador.codOp" title="Adicionar"
                                                v-on:click="addOperador">+
                                        </button>
                                    </div>
                                    <div class="message">{{ validation.firstError('item.vlMeta') }}</div>
                                </div>
                            </div>
                        </div>
                    </CTab>
                </CTabs>
            </div>
        </div>
        <div class="modal-footer">
            <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
                    v-on:click="handleSubmit">
                Inserir
            </button>
            <button class="button" v-on:click="close">Fechar</button>
        </div>
    </modal>
</template>

<script>
  import SimpleVueValidation from "simple-vue-validator";
  import moment from "moment";
  import _find from "lodash/find";
  import _filter from "lodash/filter";
  import _remove from "lodash/remove";
  import _some from "lodash/some";
  import _sumBy from "lodash/sumBy";

  import Multiselectable from "./multiselectable";
  import CloseModal from "../../../../components/CloseModal";
  import {toMonetary, toPercent} from "@/helpers/utils";

  const Validator = SimpleVueValidation.Validator.create({
    templates: {
      required: "Campo de preenchimento obrigatório",
      greaterThan: "Valor deve ser maior que {0}",
    },
  });

  const initial = () => ({
    nome: "",
    dataInicio: moment().format(),
    dataFim: moment()
      .add(1, "days")
      .format(),
    image: "",
    metasOperadores: [],
    tipoCampanha: "D", // M
    tipoApuracao: "P", // N
    dimensoes: [], //
    mix: [],
  });

  const initialDimensaoForm = () => ({
    indice: -1,
  });

  const initialDimensao = () => ({
    coluna: "",
    dimensao: "",
  });

  const initialMetaOperador = () => ({
    codOp: "",
    vlMeta: "1",
    positivacao: "1",
    percMix: "1",
  });

  export default {
    name: "cupomCreate",
    props: [
      "dimensoes",
      "operadores",
      "produtos",
      "create",
      "close",
      "descSingular",
    ],
    data() {
      return {
        item: initial(),
        dimensaoForm: initialDimensaoForm(),
        dimensao: initialDimensao(),
        metaOperador: initialMetaOperador(),
        monetary: {
          decimal: ",",
          thousands: ".",
          prefix: "R$ ",
          masked: false,
        },
      };
    },
    computed: {
      getMetaTotal() {
        return toMonetary(_sumBy(this.item.metasOperadores, "vlMeta"));
      },
      getDimensoesItens() {
        const dimensaoSelected = _find(this.dimensoes, (dim) => {
          return dim.coluna === this.dimensao.coluna;
        });

        if (dimensaoSelected) return dimensaoSelected.dimensoes;

        return [];
      },
      operadoresFiltrados() {
        return _filter(this.operadores, (op) => {
          return !_some(
            this.item.metasOperadores,
            (operadorMeta) => operadorMeta.codOp === op.codOp
          );
        });
      },
    },
    validators: {
      "item.nome": (value) => Validator.value(value).required(),
      "item.metasOperadores": (value) => Validator.value(value).required(),
      "item.dataInicio": (value) => Validator.value(value).required(),
      "item.dataFim": (value) => Validator.value(value).required(),
      "item.dimensoes, item.tipoCampanha": function (dimensoes, tipoCampanha) {
        if (tipoCampanha !== "D") return;
        return Validator.value(dimensoes).required();
      },
      "item.mix, item.tipoCampanha": function (mix, tipoCampanha) {
        if (tipoCampanha !== "M") return;
        return Validator.value(mix).required();
      },
    },
    methods: {
      handleSubmit() {
        this.$validate().then((success) => {
          if (success) {
            this.create(this.item);
            this.item = initial();
            Validator.reset();
          }
        });
      },
      getNomeOperador(codOp) {
        const operador = _find(this.operadores, (op) => +op.codOp === +codOp);
        return operador.nome;
      },
      getDescricaoDimensao(dimensao) {
        const dimensaoDesc = _find(
          this.dimensoes[dimensao.coluna - 1].dimensoes,
          (dim) => dimensao.dimensao === dim.id
        );
        const dimensaoInfo = {
          quando: this.dimensoes[dimensao.coluna - 1].label,
          for: dimensaoDesc.desc,
        };
        return dimensaoInfo.quando + " = " + dimensaoInfo.for;
      },
      changeDimensao() {
        this.dimensao = {
          coluna: this.dimensoes[this.dimensaoForm.indice].coluna,
          dimensao: this.dimensoes[this.dimensaoForm.indice].dimensoes[0].id,
        };
      },
      addOperador() {
        this.item.metasOperadores.push(this.metaOperador);
        this.resetOperadorForm();
      },
      removeOperador(i) {
        this.item.metasOperadores.splice(i, 1);
      },
      resetOperadorForm() {
        this.metaOperador = initialMetaOperador();
      },
      addDimensao() {
        this.item.dimensoes.push(this.dimensao);
        this.resetDimensaoForm();
      },
      removeDimensao(i) {
        this.item.dimensoes.splice(i, 1);
      },
      resetDimensaoForm() {
        this.dimensaoForm = initialDimensaoForm();
        this.dimensao = initialDimensao();
      },
      addProdutoMix(id) {
        this.item.mix.push(id);
      },
      removeProdutoMix(id) {
        const produtos = [...this.item.mix];
        _remove(produtos, (n) => +n === +id);
        this.item.mix = produtos;
      },
      onSelectCliente(id) {
        this.item.clientes.push(id);
      },
      onRemoveCliente(id) {
        this.item.clientes = _filter(this.item.clientes, (idCliente) => {
          return +id !== +idCliente;
        });
      },
      changeTab(tab) {
        if (this.activeTab !== tab) {
          this.activeTab = tab;
        }
      },
      reset() {
        this.item = initial();
      },
      toMonetary,
      toPercent,
    },
    components: {
      Multiselectable,
      CloseModal,
    },
    created() {
    },
  };
</script>
