<template>
    <div class="xrow">
        <div class="table-container">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th>ID</th><th>Produto</th><th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!selectedItems.length">
                        <td colspan="3" style="text-align: center;">Adicionar Produtos</td>
                    </tr>
                    <tr v-for="(i, index) in selectedItems">
                        <td>{{ i.idExibicao || i.id }}</td>
                        <td>{{ i.descComp }}</td>
                        <td>
                            <button class="button button-error" title="Remover" v-on:click="()=>deselectItem(i, index)">
                                <strong>&times;</strong>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <h2>ADICIONAR PRODUTO:</h2>
        <div class="search-select">
            <input class="form-control" placeholder="Pesquisar por ID, descrição ou referência" type="text" v-model="searchQuery" />
            <ul class="list">
               <li class="list-item" v-for="item in filteredItems" v-on:click="()=>selectItem(item)">
                <span>
                    {{ item.idExibicao || item.id }} - {{ item.descComp | truncate(80) }}
                </span>
               <button class="button button-primary add-item" title="Adicionar">&plus;</button>
               </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>
.table-container {
  max-height: 230px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.table tbody > tr:nth-child(even) td {
  background-color: #fff;
}
.table tbody > tr:nth-child(odd) td {
  background-color: #f1f1f1;
}
.table tbody > tr > td {
  padding: 6px;
}
.table td .button-error {
  padding: 1px 5px;
}

.list {
  padding: 10px 0;
  height: 200px;
  overflow-y: auto;
}
.list-item {
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  position: relative;
}
.list-item:hover {
  background-color: #ddd !important;
}
.list-item:nth-child(odd) {
  background-color: #f1f1f1;
}

.list-item:nth-child(even) {
  font-size: 14px;
}
.add-item {
  position: absolute;
  right: 8px;
  top: 6px;
  padding: 1px 5px;
}
</style>

<script>
import _filter from 'lodash/filter';
import _some from 'lodash/some';

import { normalizeString, isNumeric } from '@/helpers/utils';

export default {
  name: 'multiselectable',
  props: [
    'items',
    'itemsSelected',
    'selectCallback',
    'deselectCallback',
    'itemKey',
  ],
  data() {
    return {
      searchQuery: '',
      selectedItems: [],
    };
  },
  computed: {
    filteredItems() {
      if (this.searchQuery === '') {
        return this.diffItems(this.items);
      }
      const searchItems = _filter(this.items, (item) => {
        return (
          this.filterFields(
            this.searchQuery,
            [
              item.id,
              item.codBar,
              item.descComp,
              item.idExibicao
            ]
          )
        );
      });
      return this.diffItems(searchItems);
    },
  },
  methods: {
    selectItem(item) {
      this.selectedItems.push(item);
      this.selectCallback(item[this.itemKey]);
    },
    filterFields(searchQuery, fieldList) {
      return _some(fieldList, (field) => {
          if (!isNumeric(searchQuery)) {
            return normalizeString(String(field))
              .toLowerCase()
              .indexOf(normalizeString(searchQuery).toLowerCase()) >= 0
          }
          return +searchQuery === +field;
        }
      );
    },
    deselectItem(item, index) {
      this.selectedItems.splice(index, 1);
      this.deselectCallback(item[this.itemKey]);
    },
    diffItems(items) {
      return _filter(items, (item) => {
        return !_some(
          this.selectedItems,
          (selected) => selected.id === item.id
        );
      }).slice(0, 15);
    },
    reloadItems() {
      this.selectedItems = _filter(this.items, (item) => {
        return _some(this.itemsSelected, (selected) => selected === item.id);
      }).slice(0, 15);
    },
  },
  filters: {
    truncate: (text, stop, clamp) => {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
    },
  },
  created() {
    this.reloadItems();
  },
};
</script>
