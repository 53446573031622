<template>
    <modal name="campanhaComercialEdit" height="auto" width="900" :scrollable="true" :clickToClose="false" @before-open="loadDependencias">
        <CloseModal :close="close" />
        <div class="modalbox">
            <div class="modalbox-content">
                <CTabs variant="tabs" class="nav-tabs-boxed">
                    <CTab title="Informações">
                        <Informacao
                            :item="item"
                            :validacao="validation"
                        />
                    </CTab>
                    <CTab title="Regras">
                        <Regra
                            :item="item"
                            :dimensoes="dimensoes"
                            :produtos="produtos"
                            :validacao="validation"
                        />
                    </CTab>
                    <CTab title="Brindes">
                        <Brinde
                            :item="item"
                            :produtos="produtos"
                        />
                    </CTab>
                    <CTab title="Restrições">
                        <Restricao
                            :item="item"
                            :regioesRepresentantes="regioesRepresentantes"
                        />
                    </CTab>
                </CTabs>
            </div>
        </div>
        <div class="modal-footer">
            <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
                    v-on:click="handleSubmit"
            >
                Salvar Alterações
            </button>
            <button class="button" v-on:click="close">Fechar</button>
        </div>
    </modal>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import moment from "moment";
import { toMonetary, toPercent } from "@/helpers/utils";
import { get, post, put } from "@/helpers/apiRequest";
import Informacao from "./components/Informacao/Informacao";
import Regra from "./components/Regra/Regra";
import Brinde from "./components/Brinde/Brinde";
import Restricao from "./components/Restricao/Restricao";
import CloseModal from "../../../../components/CloseModal";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: "*obrigatório",
    greaterThan: "Valor deve ser maior que 0",
  },
});

export default {
  name: "campanhaComercialEdit",
  props: ["item", "close"],
  data() {
    return {
      regioesRepresentantes: [],
      dimensoes: [],
      produtos: [],
    };
  },
  components: {
    Informacao,
    Regra,
    Brinde,
    Restricao,
    CloseModal,
  },
  validators: {
    "item.descricao": (value) => Validator.value(value).required(),
    "item.funcionamento": (value) => Validator.value(value).required(),
    "item.dataInicio": (value) => Validator.value(value).required(),
    "item.dataFim": (value) => Validator.value(value).required(),
    "item.validacao": (value) => Validator.value(value).required(),
    "item.tipo": (value) => Validator.value(value).required(),
    "item.dimensoes, item.tipo": function(dimensoes, tipo) {
      if (tipo === "SEGMENTACAO") {
        return Validator.value(dimensoes).required();
      }
    },
    "item.mixProdutos, item.tipo": function(mixProdutos, tipo) {
      if (tipo === "MIX") {
        return Validator.value(mixProdutos).required();
      }
    },
    // 'item.qtdeMin, item.validacao': function(qtdeMin, validacao) {
    //   if (
    //     validacao === 'QT' ||
    //     validacao === 'VQ' ||
    //     validacao === 'QQ' ||
    //     validacao === 'XX'
    //   ) {
    //     return Validator.value(qtdeMin).required();
    //   }
    // },
    // 'item.qtdeMax, item.validacao': function(qtdeMax, validacao) {
    //   if (
    //     validacao === 'QT' ||
    //     validacao === 'VQ' ||
    //     validacao === 'QQ' ||
    //     validacao === 'XX'
    //   ) {
    //     return Validator.value(qtdeMax).required();
    //   }
    // },
    // 'item.qtdeMinDistinta, item.validacao': function(
    //   qtdeMinDistinta,
    //   validacao
    // ) {
    //   if (
    //     validacao === 'QD' ||
    //     validacao === 'VD' ||
    //     validacao === 'QQ' ||
    //     validacao === 'XX'
    //   ) {
    //     return Validator.value(qtdeMinDistinta).required();
    //   }
    // },
    // 'item.qtdeMaxDistinta, item.validacao': function(
    //   qtdeMaxDistinta,
    //   validacao
    // ) {
    //   if (
    //     validacao === 'QD' ||
    //     validacao === 'VD' ||
    //     validacao === 'QQ' ||
    //     validacao === 'XX'
    //   ) {
    //     return Validator.value(qtdeMaxDistinta).required();
    //   }
    // },
    // 'item.vlMin, item.validacao': function(vlMin, validacao) {
    //   if (
    //     validacao === 'VD' ||
    //     validacao === 'VQ' ||
    //     validacao === 'VL' ||
    //     validacao === 'XX'
    //   ) {
    //     return Validator.value(vlMin).required();
    //   }
    // },
    // 'item.vlMax, item.validacao': function(vlMax, validacao) {
    //   if (
    //     validacao === 'VD' ||
    //     validacao === 'VQ' ||
    //     validacao === 'VL' ||
    //     validacao === 'XX'
    //   ) {
    //     return Validator.value(vlMax).required();
    //   }
    // },
    // 'item.percMin, item.validacao': function(percMin, validacao) {
    //   if (validacao === 'PM') {
    //     return Validator.value(percMin).required();
    //   }
    // },
  },
  watch: {
    campanha: function(value) {
      this.currentTab = "informacao";
    },
  },
  methods: {
    normalizeItem(item) {
      item.dataInicio = item.dataInicio
        ? moment(item.dataInicio).format("YYYY-MM-DD")
        : null;
      item.dataFim = item.dataFim
        ? moment(item.dataFim).format("YYYY-MM-DD")
        : null;
      item.brinde = item.brindes.length > 0;
      return item;
    },
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          if (this.item.id) {
            this.update(this.normalizeItem(this.item));
            return;
          }
          this.create(this.normalizeItem(this.item));
        }
      });
    },
    changeTab(id) {
      this.currentTab = id;
    },
    loadDimensoes() {
      this.loading = true;

      get("/admin/campanhas-dimensoes")
        .then((json) => {
          this.dimensoes = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadProdutos() {
      this.loading = true;

      get("/admin/campanhas-produtos")
        .then((json) => {
          this.produtos = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadRegioesRepresentantes() {
      this.loading = true;

      get("/admin/regioes-representantes")
        .then((json) => {
          this.regioesRepresentantes = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadDependencias() {
      this.loadDimensoes();
      this.loadProdutos();
      this.loadRegioesRepresentantes();
      this.validation.reset();
    },
    create(item) {
      this.loading = true;

      post("/admin/campanhas-comerciais", item)
        .then((json) => {
          this.close();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            title: "Falha ao cadastrar",
            text: err.message,
            icon: "error",
          });
        });
    },
    update(item) {
      this.loading = true;

      put(`/admin/campanhas-comerciais/${item.id}`, item)
        .then((json) => {
          this.close();
        })
        .catch((err) => {
          this.loading = false;
          this.$swal({
            title: "Falha ao cadastrar",
            text: err.message,
            icon: "error",
          });
        });
    },
    toMonetary,
    toPercent,
  },
};
</script>
