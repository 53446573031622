<style scoped>
.regra {
  position: relative;
  border: 2px solid #ddd;
  padding: 12px;
  margin-bottom: 24px;
}

.regra + .regra::before {
  position: absolute;
  text-align: center;
  content: 'Ou a regra abaixo deve ser válida';
  top: -22px;
}

.regra-and + .regra-and::before {
  content: 'E a regra abaixo também deve válida';
}

.regra-container {
  display: flex;
  position: relative;
  margin-bottom: 16px;
}

.regra-dimensao {
  position: relative;
  margin-right: 16px;
}

.regra-dimensao + .regra-dimensao::before {
  position: absolute;
  bottom: 5px;
  left: -12px;
  content: 'e';
}

.regra-dimensao label {
  font-size: 12px;
  font-weight: bold;
}

.button-delete-container input {
  width: 50px;
  text-align: right;
  border: 1px solid #999;
}

.button-delete-container {
  overflow: hidden;
  display: block;
}

.button-delete-container button {
  float: right;
}
</style>

<template>
  <div>
    <div class="xrow">
      <div class="col-1-2">
        <div class="form-group">
          <label>Tipo de campanha:</label>
          <select class="form-control" v-model="item.validacao">
            <option value="QT">Quantidade total de itens</option>
            <option value="QD">Quantidade distinta de itens</option>
            <option value="VD">Valor mínimo e quantidade distinta de itens</option>
            <option value="VQ">Valor mínimo e quantidade total de itens</option>
            <option value="VL">Valor mínimo</option>
            <option value="QQ">Quantidade distinta e total de itens</option>
            <option value="XX">Valor mínimo, quantidade distinta e total de itens</option>
            <option value="PM">Percentual mínimo</option>
            <option value="PMD">Percentual mínimo por segmento</option>
          </select>
          <div class="message">{{ validacao.firstError("item.validacao") }}</div>
        </div>
      </div>
    </div>
    <div class="xrow">
      <div class="col-1-1 form-group">
        <!--<small v-if="item.validacao === 'QT'">-->
        <!--Regra para validar a quantidade mínima de um determinado item ou segmento, contabilizando a quantidade total de itens-->
        <!--</small>-->
        <!--<small v-if="item.validacao === 'QD'">-->
        <!--Quantidade distinta de itens-->
        <!--</small>-->
        <!--<small v-if="item.validacao === 'VD'">-->
        <!--Valor mínimo e quantidade distinta de itens-->
        <!--</small>-->
        <!--<small v-if="item.validacao === 'VQ'">-->
        <!--Valor mínimo e quantidade total de itens-->
        <!--</small>-->
        <!--<small v-if="item.validacao === 'VL'">-->
        <!--Valor mínimo-->
        <!--</small>-->
        <!--<small v-if="item.validacao === 'QQ'">-->
        <!--Quantidade distinta e total de itens-->
        <!--</small>-->
        <!--<small v-if="item.validacao === 'XX'">-->
        <!--Valor mínimo, quantidade distinta e total de itens-->
        <!--</small>-->
        <small v-if="item.validacao === 'PM'">
          Regra por percentual mínimo utilizando o valor do pedido como referência
        </small>
        <small v-if="item.validacao === 'PMD'">
          Regra por percentual mínimo por segmento. Será considerado o valor em R$ do segmento da regra de aplicacação
          para calcular o percentual que a regra de validação tem sobre ela.
        </small>
      </div>
    </div>
    <div class="xrow" v-if="!item.validarPorRegra">
      <div class="col-1-1">
        <TipoValidacao
          :item="item"
          :inputShouldBeVisible="inputShouldBeVisible"
          :validacao="validacao"
        />
      </div>
    </div>
    <div class="xrow">
      <div class="col-1-3">
        <div class="form-group">
          <label>Tipo de validação das regras:</label>
          <select class="form-control" v-model="item.tipo"
                  :disabled="(item.tipo === 'SEGMENTACAO' && item.dimensoes.length > 0) || (item.tipo === 'MIX' && item.mixProdutos.length > 0)">
            <option value="SEGMENTACAO">Segmento</option>
            <option value="MIX" :disabled="item.validacao == 'PM' || item.validacao == 'PMD'">Mix de Produtos</option>
          </select>
          <div class="message">{{ validacao.firstError("item.tipo") }}</div>
        </div>
      </div>
    </div>
    <div class="xrow">
      <div class="col-1-3">
        <div class="form-group">
          <label>
            <span v-if="item.tipo === 'SEGMENTACAO'">Todas as regras precisam ser válidas?</span>
            <span v-if="item.tipo === 'MIX'">Pedido precisa ter todos os produtos?</span>
          </label>
          <select class="form-control" v-model="item.validarTodasRegras">
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
      <div class="col-1-3" v-if="item.tipo === 'SEGMENTACAO'">
        <div class="form-group">
          <label>
            <span>Usar validação por regra?</span>
          </label>
          <select class="form-control" v-model="item.validarPorRegra">
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
      <div class="col-1-3">
        <div class="form-group">
          <label>
            <span>Considera quantidade de estoque?</span>
          </label>
          <select class="form-control" v-model="item.consideraQtdeEstoque">
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
      <div class="col-1-3">
        <div class="form-group">
          <label>
            <span>Aplica sobre promoção?</span>
          </label>
          <select class="form-control" v-model="item.aplicarSobrePromocao">
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
      <div class="col-1-3">
        <div class="form-group">
          <label>
            <span>Aplica sobre negociação?</span>
          </label>
          <select class="form-control" v-model="item.aplicarSobreNegociacao">
            <option :value="true">Sim</option>
            <option :value="false">Não</option>
          </select>
        </div>
      </div>
    </div>
    <div class="xrow" v-if="item.tipo === 'SEGMENTACAO'">
      <div class="col-1-1">
        <div v-for="(regra, index) in item.dimensoes" >
          <div v-if="validaRegraByDimensao(regra)" v-bind:class="{ regra, 'regra-and': item.validarTodasRegras }" class="regra">
            <h4>Regra de validação</h4>
           
            <div class="regra-container">
              <div v-for="dimensao in dimensoes" class="regra-dimensao">
                <label>{{ dimensao.label }}</label>
                <select class="form-control" v-model="regra[`dimensao${dimensao.coluna}`]"
                        v-on:change="clonarRegra(regra)">
                  <option></option>
                  <option v-for="itemDimensao in dimensao.dimensoes" :value="itemDimensao.id">{{
                      itemDimensao.desc
                    }}
                  </option>
                </select>
                <div class="message">{{ validacao.firstError("item.tipo") }}</div>
              </div>
            </div>
            <h4>
              Regra de aplicação |
              <small>
                <label>
                  <input type="checkbox" v-model="regra.aplicaregra" :value="true" v-on:change="clonarRegra(regra)"/>
                  Aplicar o desconto nos mesmos itens da regra de validação
                </label>
              </small>
            </h4>
            <div class="regra-container">
              <div v-for="dimensao in dimensoes" class="regra-dimensao">
                <label>{{ dimensao.label }}</label>
                <select
                  class="form-control"
                  v-model="regra[`dimensaoaplicacao${dimensao.coluna}`]"
                  :disabled="!!regra.aplicaregra"
                >
                  <option></option>
                  <option v-for="itemDimensao in dimensao.dimensoes" :value="itemDimensao.id">{{
                      itemDimensao.desc
                    }}
                  </option>
                </select>
                <div class="message">{{ validacao.firstError("item.tipo") }}</div>
              </div>
            </div>
            <div v-if="item.validarPorRegra">
              <TipoValidacao
                :item="regra"
                :inputShouldBeVisible="inputShouldBeVisible"
                :validacao="validacao"
              />
            </div>
            <div class="button-delete-container">
              Aplicar o desconto de
              <input type="number" v-model="regra.percDesc"/>%
              nos itens que compõem a regra de aplicação

              <button
                type="button"
                class="button button-error"
                v-on:click="excluirRegra(index)"
              >
                <fa-icon icon="trash-alt"/>
              </button>
            </div>
          </div>
        </div>

 
        <button
          type="button"
          class="button button-primary"
          v-on:click="adicionarRegra"
        >
          Adicionar Regra
        </button>
      </div>
    </div>
    <div class="xrow" v-else>
      <div class="col-1-1">
        <label>
          <strong>Produtos Selecionados:</strong>
          <small class="validation-error">{{ validacao.firstError("item.mixProdutos") }}</small>
        </label>
        <ListaProdutos
          :campanha="item"
          :items="produtos"
          :itemsSelected="item.mixProdutos"
          itemKey="id"
          :select-callback="addProdutoMix"
          :deselect-callback="removeProdutoMix"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ListaProdutos from './ListaProdutos';
import _remove from 'lodash/remove';
import TipoValidacao from "./TipoValidacao";
import clone from 'just-clone';
import _find from "lodash/find";

const initialDimensao = () => ({
  dimensao1: '',
  dimensao2: '',
  dimensao3: '',
  dimensao4: '',
  dimensao5: '',
  dimensao6: '',
  dimensao7: '',
  dimensao8: '',
  dimensao9: '',
  dimensao10: '',
  dimensaoaplicacao1: '',
  dimensaoaplicacao2: '',
  dimensaoaplicacao3: '',
  dimensaoaplicacao4: '',
  dimensaoaplicacao5: '',
  dimensaoaplicacao6: '',
  dimensaoaplicacao7: '',
  dimensaoaplicacao8: '',
  dimensaoaplicacao9: '',
  dimensaoaplicacao10: '',
  percDesc: 0,
  aplicaregra: true,
  qtdeMin: 0,
  qtdeMax: null,
  qtdeMinDistinta: 0,
  qtdeMaxDistinta: null,
  vlMin: 0,
  vlMax: 0,
  percMin: 0,
  percMax: 0,
});

export default {
  name: 'Regra',
  props: [
    'item',
    'dimensoes',
    'produtos',
    'validacao'
  ],
  data() {
    return {
      dimensao: initialDimensao(),
      percDescDimensao: null,
      percDescProduto: null,
      tiposInputs: {
        QT: ['qtdeMin', 'qtdeMax'],
        QD: ['qtdeMinDistinta', 'qtdeMaxDistinta'],
        VD: ['vlMin', 'vlMax', 'qtdeMinDistinta', 'qtdeMaxDistinta'],
        VQ: ['vlMin', 'vlMax', 'qtdeMin', 'qtdeMax'],
        VL: ['vlMin', 'vlMax'],
        QQ: ['qtdeMin', 'qtdeMax', 'qtdeMinDistinta', 'qtdeMaxDistinta'],
        XX: [
          'vlMin',
          'vlMax',
          'qtdeMinDistinta',
          'qtdeMaxDistinta',
          'qtdeMin',
          'qtdeMax',
        ],
        PM: ['percMin', 'percMax'],
        PMD: ['percMin', 'percMax'],
      },
    };
  },
  components: {
    TipoValidacao,
    ListaProdutos,
  },
  watch: {
    'item.tipo'() {
      if (this.item.tipo === 'MIX') {
        this.item.validarPorRegra = false
      }
    },
    'item.validacao'(oldValue, newValue) {
      this.item.qtdeMin = 0;
      this.item.qtdeMax = null;
      this.item.qtdeMinDistinta = 0;
      this.item.qtdeMaxDistinta = null;
      this.item.vlMin = 0;
      this.item.vlMax = 0;
      this.item.percMin = 0;
      this.item.percMax = 0;
      this.item.tipo = 'SEGMENTACAO';
      this.item.mixProdutos = []
    },
    'item.validarPorRegra'(oldValue, newValue) {
      this.item.qtdeMin = 0;
      this.item.qtdeMax = null;
      this.item.qtdeMinDistinta = 0;
      this.item.qtdeMaxDistinta = null;
      this.item.vlMin = 0;
      this.item.vlMax = 0;
      this.item.percMin = 0;
      this.item.percMax = 0;

      this.item.dimensoes.map(dimensao => {
        dimensao.qtdeMin = 0;
        dimensao.qtdeMax = null;
        dimensao.qtdeMinDistinta = 0;
        dimensao.qtdeMaxDistinta = null;
        dimensao.vlMin = 0;
        dimensao.vlMax = 0;
        dimensao.percMin = 0;
        dimensao.percMax = 0;
      })

    }
  },
  methods: {
    adicionarRegra() {
      this.item.dimensoes.push(initialDimensao())
    },
    excluirRegra(index) {
      this.item.dimensoes.splice(index, 1)
    },
    clonarRegra(regra) {
      if (regra.aplicaregra) {
        for (let idx = 1; idx <= 10; idx++) {
          regra[`dimensaoaplicacao${idx}`] = regra[`dimensao${idx}`]
        }
      }
    },
    validaRegraByDimensao(regra){
     
     if (!this.validaDimensao(regra.dimensao1,1)) return false;
     if (!this.validaDimensao(regra.dimensao2,2)) return false;
     if (!this.validaDimensao(regra.dimensao3,3)) return false;
     if (!this.validaDimensao(regra.dimensao4,4)) return false;
     if (!this.validaDimensao(regra.dimensao5,5)) return false;
     if (!this.validaDimensao(regra.dimensao6,6)) return false;
     if (!this.validaDimensao(regra.dimensao7,7)) return false;
     if (!this.validaDimensao(regra.dimensao8,8)) return false;
     if (!this.validaDimensao(regra.dimensao9,9)) return false;
     if (!this.validaDimensao(regra.dimensao10,10)) return false;

     if (!this.validaDimensao(regra.dimensaoaplicacao1,1)) return false;
     if (!this.validaDimensao(regra.dimensaoaplicacao2,2)) return false;
     if (!this.validaDimensao(regra.dimensaoaplicacao3,3)) return false;
     if (!this.validaDimensao(regra.dimensaoaplicacao4,4)) return false;
     if (!this.validaDimensao(regra.dimensaoaplicacao5,5)) return false;
     if (!this.validaDimensao(regra.dimensaoaplicacao6,6)) return false;
     if (!this.validaDimensao(regra.dimensaoaplicacao7,7)) return false;
     if (!this.validaDimensao(regra.dimensaoaplicacao8,8)) return false;
     if (!this.validaDimensao(regra.dimensaoaplicacao9,9)) return false;
     if (!this.validaDimensao(regra.dimensaoaplicacao10,10)) return false;

     return true;
   },
   validaDimensao(dimensao, coluna){
      if (dimensao == null || dimensao == '' || !dimensao) return true;
     var dimensoes = clone(this.dimensoes);

     const _dimensao = _find(this.dimensoes, (d) => d.coluna === coluna);

     if (!_dimensao){return  false;}
     
     var valida = false;
     _dimensao.dimensoes.forEach(e => {
       if (e.id === dimensao){
         valida = true;
       }
     })
      
     return valida;
   },
    addProdutoMix(item) {
      this.item.mixProdutos.push(item);
    },
    removeProdutoMix(id) {
      const produtos = [...this.item.mixProdutos];
      _remove(produtos, (n) => +n.id === +id);
      this.item.mixProdutos = produtos;
    },
    inputShouldBeVisible(inputName) {
      return (
        this.tiposInputs[this.item.validacao] &&
        this.tiposInputs[this.item.validacao].indexOf(inputName) >= 0
      );
    },
  },
};
</script>
