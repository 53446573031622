<template>
  <div>
    <Create
      :dimensoes="dimensoes"
      :operadores="operadores"
      :produtos="produtos"
      :create="createItem"
      :close="closeCreate"
      :descSingular="descSingular"
    ></Create>
    <Edit
      :item="currentItem"
      :dimensoes="dimensoes"
      :operadores="operadores"
      :produtos="produtos"
      :update="updateItem"
      :close="closeEdit"
      :descSingular="descSingular"
    ></Edit>
    <div class="filters row" style="margin-top:0">
      <div class="col-1-4">
        <div class="form-group">
          <datetime
              input-class="form-control"
              v-model="filtros.data"
              type="date"
              v-on:input="loadCampanhasPorData"
              :max-datetime="filtros.dataFim"
              placeholder="Filtrar por data"
              :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
              value-zone="local"
          />
        </div>
      </div>
      <div class="col-1-4">
        <div v-if="filtros.data" class="form-group">
          <button class="button button-primary" v-on:click="limpaFiltros">Limpar</button>
        </div>
      </div>
      <div class="col-1-2">
        <div class="pull-right">
          <button v-on:click="openCreate" class="button button-success button-block">
            <fa-icon icon="plus" />
            Criar
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Data Inicial</th>
            <th>Data Final</th>
            <th>Tipo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items">
            <td class="highlight">{{ item.nome }}</td>
            <td>{{ moment(item.dataInicio).format('DD/MM/YYYY HH:mm') }}</td>
            <td>{{ moment(item.dataFim).format('DD/MM/YYYY HH:mm') }}</td>
            <td>
              <span v-if="item.tipoCampanha ==='D'">Dimensão</span>
              <span v-if="item.tipoCampanha ==='M'">Mix</span>
            </td>
            <td class="actions">
              <button type="button" class="button button-primary" v-on:click="openEdit(item)"><fa-icon icon="pencil-alt" /></button>
              <button type="button" name="button" v-on:click="deleteConfirm(item.id)" class="button button-error"><fa-icon icon="trash-alt" /></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import clone from 'just-clone';
import { get, post, put, del } from '@/helpers/apiRequest';
import { toDateTime } from '@/helpers/utils';
import _map from 'lodash/map';
import moment from 'moment';
import Create from './Create';
import Edit from './Edit';

export default {
  name: 'objetivos-operadores',
  components: {
    Create,
    Edit,
  },
  data() {
    return {
      descPlural: '',
      descSingular: '',
      loading: false,
      items: [],
      dimensoes: [],
      operadores: [],
      produtos: [],
      filtros: {
        data: '',
      },
      currentItem: {},
      currentAccess: false,
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_CAMPANHA'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    loadCampanhas() {
      this.loading = true;
      get('/admin/campanhas')
        .then((json) => {
          this.items = this.midllewareCampanhas(json);
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadCampanhasPorData(data) {
      if (data) {
        this.loading = true;
        const dataFormat = moment(data).format('YYYY-MM-DD');
        get(`/admin/campanhas?data=${dataFormat}`)
          .then((json) => {
            this.items = this.midllewareCampanhas(json);
            this.loading = false;
          })
          .catch(() => (this.loading = false));
      }
      return false;
    },
    limpaFiltros() {
      this.filtros.data = '';
      this.loadCampanhas();
    },
    midllewareCampanhas(campanhas) {
      return _map(campanhas, (i) => {
        return {
          ...i,

          dataInicio: moment(i.dataInicio).format(),
          dataFim: moment(i.dataFim).format(),
        };
      });
    },
    loadDimensoes() {
      this.loading = true;
      get('/admin/campanhas-dimensoes')
        .then((json) => {
          this.dimensoes = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadOperadores() {
      this.loading = true;
      get('/admin/campanhas-operadores')
        .then((json) => {
          this.operadores = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadProdutos() {
      this.loading = true;
      get('/admin/campanhas-produtos')
        .then((json) => {
          this.produtos = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    openCreate() {
      this.$modal.show('campanhaCreate');
    },
    closeCreate() {
      this.$modal.hide('campanhaCreate');
    },
    createItem(item) {
      this.loading = true;
      post('/admin/campanhas', item).then((json) => {
        this.loadCampanhas();
        this.loading = false;
        this.closeCreate();
      });
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.$modal.show('campanhaEdit');
    },
    closeEdit() {
      this.$modal.hide('campanhaEdit');
    },
    updateItem() {
      this.loading = true;
      const { id } = this.currentItem;

      put(`/admin/campanhas/${id}`, this.currentItem)
        .then((json) => {
          this.loading = false;
          this.loadCampanhas();
          this.closeEdit();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/campanhas/${id}`)
              .then((json) => {
                this.loading = false;
                this.loadCampanhas();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },
    toDateTime,
    moment,
  },
  beforeMount() {
    this.loadDimensoes();
    this.loadOperadores();
    this.loadProdutos();
    this.loadCampanhas();
    this.loadDescricaoModulo();
  },
};
</script>
