<template>
    <div>
        <Edit
                :item="item"
                :close="closeEdit"
        />
        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;">
            <div class="form-group" style="display: flex; align-items: center">
                <datetime
                        input-class="form-control"
                        v-model="filtros.data"
                        type="date"
                        placeholder="Vigentes em"
                        :phrases="{ok: 'Confirmar', cancel: 'Fechar'}"
                        value-zone="local"
                />
                <input
                        class="form-control ml-1"
                        type="text"
                        v-model="filtros.termo"
                        placeholder="Descrição"
                        v-on:keyup.enter="loadCampanhas"
                />
                <button class="button button-primary ml-1" v-on:click="loadCampanhas">
                    Buscar
                </button>
            </div>
            <button v-on:click="openCreate" class="button button-success">
                <fa-icon icon="plus"/>
                Criar
            </button>
        </div>
        <div class="table-responsive">
            <datatable
                :columns="table.columns"
                :data="campanhas"
                class="table"
            >
                <template slot-scope="{ row }">
                    <tr>
                        <td style="text-align: center">{{ row.id }}</td>
                        <td style="text-align: left">{{ row.descricao }}</td>
                        <td style="text-align: center">{{ row.dataInicio | date }}</td>
                        <td style="text-align: center">{{ row.dataFim | date }}</td>
                        <td style="text-align: center">{{ row.tipo }}</td>
                        <td style="text-align: center">{{ row.validacao }}</td>
                        <td class="actions">
                            <button
                                    type="button"
                                    class="button button-success"
                                    v-on:click="cloneCampanha(row)"
                            >
                                <fa-icon icon="clone"/>
                            </button>

                            <button
                                    type="button"
                                    class="button button-primary"
                                    v-on:click="editCampanha(row)"
                            >
                                <fa-icon icon="pencil-alt"/>
                            </button>

                            <button
                                    type="button"
                                    name="button"
                                    v-on:click="deleteConfirm(row.id)"
                                    class="button button-error"
                            >
                                <fa-icon icon="trash-alt"/>
                            </button>
                        </td>
                    </tr>
                </template>
            </datatable>
        </div>
        <div style="display: flex; justify-content: center;">
            <pagination
                    :data="table.paginator"
                    :limit="5"
                    @pagination-change-page="loadCampanhas"
            ></pagination>
        </div>
    </div>
</template>

<script>
import { get, del, put, post } from '@/helpers/apiRequest';
import moment from 'moment';
import Edit from './Edit';
import clone from 'just-clone';

const initial = {
    descricao: "",
    funcionamento: "",
    dataInicio: moment().format(),
    dataFim: moment().add(1, "days").format(),
    qtdeMin: 0,
    qtdeMax: null,
    qtdeMinDistinta: 0,
    qtdeMaxDistinta: null,
    vlMin: 0,
    vlMax: 0,
    percMin: 0,
    percMax: 0,
    validarTodasRegras: false,
    validarPorRegra: false,
    consideraQtdeEstoque: false,
    aplicarSobrePromocao: false,
    aplicarSobreNegociacao: false,
    tipo: "SEGMENTACAO",
    validacao: "QT",
    brindes: [],
    dimensoes: [],
    mixProdutos: [],
    restricoes: []
};

export default {
  name: 'campanhas-comerciais',
  components: {
    Edit,
  },
  data() {
    return {
      loading: false,
      item: {},
      campanhas: [],
      filtros: {
        data: '',
        termo: '',
      },
      table: {
        columns: [
          { label: 'ID', field: 'id', align: 'center', sortable: false },
          { label: 'Descrição', field: 'descricao', sortable: false },
          {
            label: 'Data Inicial',
            field: 'dataInicio',
            align: 'center',
            sortable: false,
          },
          {
            label: 'Data Final',
            field: 'dataFim',
            align: 'center',
            sortable: false,
          },
          { label: 'Tipo', field: 'tipo', align: 'center', sortable: false },
          {
            label: 'Validação',
            field: 'validacao',
            align: 'center',
            sortable: false,
          },
          { label: '', field: '', sortable: false },
        ],
        paginator: {},
      },
    };
  },
  methods: {
    loadCampanhas(page = 1) {
      this.loading = true;
      const date = this.getDate();

      get(
        `/admin/campanhas-comerciais?page=${page}&termo=${
          this.filtros.termo
        }&data=${date}`
      )
        .then((json) => {
          this.campanhas = json.itens;
          this.table.paginator = json.paginator;
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    editCampanha(campanha) {
      this.loading = true;

      get(`/admin/campanhas-comerciais/${campanha.id}`)
        .then((json) => {
          this.item = clone(json);
          this.openEdit();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cloneCampanha(campanha) {
          this.loading = true;

          get(`/admin/campanhas-comerciais/${campanha.id}`)
              .then(json => {
                  const cloneCampanha = clone(json);
                  cloneCampanha.id = null;
                  cloneCampanha.descricao = `Cópia de ${json.descricao}`;

                  this.item = cloneCampanha;
                  this.openEdit()
              })
              .finally(() => {
                  this.loading = false;
              });
      },
      openEdit() {
      this.$modal.show('campanhaComercialEdit');
    },
    openCreate() {
      this.item = { ...initial };
      this.$modal.show('campanhaComercialEdit');
    },
    closeEdit() {
      this.$modal.hide('campanhaComercialEdit');
      this.loadCampanhas();
    },
    deleteConfirm(id) {
      this.$swal({
        title: 'Confirma Remover?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/campanhas-comerciais/${id}`)
              .then(() => {
                this.cleanFiltros();
                this.loadCampanhas();
                this.loading = false;
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: 'Falha ao remover',
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },
    getDate() {
      if (this.filtros.data) {
        return moment(String(this.filtros.data)).format('YYYY-MM-DD');
      }

      return '';
    },
    cleanFiltros() {
      this.filtros.termo = '';
      this.filtros.data = '';
    },
  },
  beforeMount() {
    this.loadCampanhas();
  },
};
</script>
